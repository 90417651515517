import React from 'react';
import logo from '../assets/logo.svg';
import styles from '../styles/App.module.scss';

const App = () => {
  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        <img src={logo} className={styles.AppLogo} alt="logo"/>
        <p>András Ádám</p>
      </header>
    </div>
  );
};

export default App;
